import React, { useEffect, useMemo, useRef } from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useExperienceLandingQuery, useGetExperienceByIdQuery } from '@vizsla/graphql';
import { DateFormatPatterns } from '@vizsla/constants';
import {
  selectAllDonations,
  selectAllRegistrations,
  useCurrentUser,
  useNotification,
  useShoppingCartState,
} from '@vizsla/hooks';
import { DonationFrecuency } from '@vizsla/types';
import { DonationForm, DonationFormValues, Form, useFormState } from '@vizsla/components';
import { validateWithSchema, formatDate, getNowDate, toDateTimeFromISO } from '@vizsla/utils';

import { useCurrentOrganization } from 'src/hooks/organization';
import { ExperiencePurchaseLayout } from 'src/layouts';
import { DONATION_FORM_SCHEMA } from 'src/constants/validationSchemas';
import { useOpenExperiencePages } from 'src/hooks';

import { Button, Buttons, Content, OrganizationSubtitle } from './ExperienceDonationStep.style';
import { useEventStoreContext } from '../../providers/EventStoreProvider';

const currentDate = String(toDateTimeFromISO(getNowDate()));
const INITIAL_VALUES: DonationFormValues = {
  frecuency: DonationFrecuency.ONE_TIME,
  startDate: currentDate,
};

export const selectDonationState = state => ({
  donation: selectAllDonations(state).at(0),
  registration: selectAllRegistrations(state),
  addCart: state.add,
  removeCart: state.remove,
  items: state.items,
});

export function ExperienceDonationStep() {
  const { donation, addCart, removeCart, items } = useShoppingCartState(selectDonationState);
  const { experienceId } = useParams();
  const { setDonation } = useEventStoreContext();
  const { organization } = useCurrentOrganization();
  const { data: dataExperience } = useGetExperienceByIdQuery({
    variables: {
      id: experienceId ?? '',
    },
  });

  const { data: experienceData } = useExperienceLandingQuery({
    variables: { id: experienceId as string },
  });
  const { user } = useCurrentUser();
  const {
    openRegistrationForm: openOptionsSelector,
    openAssetsForm,
    openBillingAddress,
    openSwagBagsForm,
    openSelectTeam,
  } = useOpenExperiencePages();
  const donationWasAdded = useRef(false);

  const ticketing = useMemo(() => {
    return experienceData?.experience?.experienceType === 'Ticketing';
  }, [experienceData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (donationWasAdded.current && donation) {
      openBillingAddress(experienceId);
      donationWasAdded.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (ticketing) {
      openOptionsSelector(experienceId);
    }
  }, [ticketing, experienceId, openOptionsSelector]);

  const toaster = useNotification();
  const customer = React.useMemo(() => {
    return user?.customer ?? undefined;
  }, [user]);
  const initialValues = React.useMemo(
    () => ({
      ...INITIAL_VALUES,
      amount: donation?.price ?? INITIAL_VALUES.amount,
      message: donation?.message ?? INITIAL_VALUES.message,
    }),
    [donation],
  );
  const validateSchema = (values: DonationFormValues) => {
    return validateWithSchema(DONATION_FORM_SCHEMA, values);
  };

  const handleStartOver = () => {
    openOptionsSelector(experienceId);
    setDonation(false);
  };

  const handleBack = () => {
    setDonation(false);
    if (dataExperience?.experience?.isStoreEnabled) {
      openAssetsForm(experienceId);
    } else if (experienceData?.experience?.experienceType === 'Ticketing') {
      openSelectTeam(experienceId);
    } else {
      openSwagBagsForm(experienceId);
    }
  };

  const handleSubmit = (values: DonationFormValues) => {
    const price = values.amount ?? 0;
    const makeAnonymous = values.makeAnonymous ?? false;

    if (!experienceData?.experience?.id) {
      toaster.error(`Experience ID must be defined. Got ${typeof experienceData?.experience?.id}`);
      return;
    }

    if (!user?.id) {
      toaster.error(`User ID must be defined. Got ${typeof user?.id}`);
      return;
    }
    if (donation) removeCart(donation);

    setDonation(true);

    addCart({
      type: 'donation',
      donor: { id: user.id },
      allocation: { type: 'experience', id: experienceData?.experience?.id },
      experience: { id: experienceData?.experience?.id },
      message: values.message,
      makeAnonymous,
      price,
      frecuency: values.frecuency,
      questions: values.donorsGroupquestions,
      startDate: formatDate(values.startDate, DateFormatPatterns.shortDateWithDash),
      endDate: values.endDate
        ? formatDate(values.endDate, DateFormatPatterns.shortDateWithDash)
        : undefined,
      organizationId: experienceData?.experience?.campaign?.vizslaOrganization?.id ?? '',
      integrationType: values.integrationType,
      integrationCredentials: values.integrationCredentials,
      integrationCompany: values.integrationCompany,
    });
    donationWasAdded.current = true;
  };

  const skipStep = () => {
    if (!experienceData?.experience?.id) {
      toaster.error(`Experience ID must be defined. Got ${typeof experienceData?.experience?.id}`);
      return;
    }
    if (donation) removeCart(donation);
    openBillingAddress(experienceId);
  };
  return (
    <ExperiencePurchaseLayout>
      {ticketing ? (
        <Typography>Donations not available for this experience</Typography>
      ) : (
        <Form initialValues={initialValues} onSubmit={handleSubmit} validate={validateSchema}>
          {({ handleSubmit, invalid }) => (
            <Content>
              <OrganizationSubtitle>
                {`Would you like to make a donation${
                  organization?.name ? ` to ${organization.name}` : ''
                }?`}
              </OrganizationSubtitle>
              <DonationForm
                experienceId={experienceData?.experience?.id as string}
                customer={customer}
                organizationId={organization?.id as string}
              />
              <Buttons>
                <Button variant="outlined" onClick={handleStartOver}>
                  Start Over
                </Button>

                <Button variant="outlined" onClick={handleBack}>
                  Back
                </Button>

                <Button variant="outlined" onClick={skipStep}>
                  Skip
                </Button>

                <Button onClick={handleSubmit} disabled={invalid}>
                  Continue
                </Button>
              </Buttons>
            </Content>
          )}
        </Form>
      )}
    </ExperiencePurchaseLayout>
  );
}
