import React from 'react';
import { Grid } from '@mui/material';

import { CampaignTeamMember, Team_FundraiserLandingItem } from '@vizsla/graphql';
import { TableData } from '@vizsla/components';

import { useRaisedTable } from '../hooks/useRaisedTable';
import { MEMBERS } from '../constants/raisedTypes';

interface MembersTableProps {
  members: CampaignTeamMember[] | null | undefined;
  styles?: any;
  loading?: boolean;
  type?: string;
  team: Team_FundraiserLandingItem;
}

export const RaisedTable: React.FC<MembersTableProps> = ({
  styles,
  members,
  loading = false,
  type = MEMBERS,
  team,
}) => {
  const { getColumnConfiguration } = useRaisedTable();
  const columns = getColumnConfiguration(type, team);

  return (
    <Grid>
      <TableData dataSource={members ?? []} columns={columns} loading={loading} />
    </Grid>
  );
};
