import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { omit } from 'lodash';

import { TabContainer, TabItem } from '@vizsla/components';
import { useCurrentUser, useShoppingCartState } from '@vizsla/hooks';
import { isShoppingCartRegistrationItem } from '@vizsla/utils';

import { ExperiencePurchaseLayout } from 'src/layouts';
import { useCurrentExperience, useOpenExperiencePages } from 'src/hooks';

import { Container, TabContext, TabPanel } from './ExperienceTeamStep.styles';
import { ExperienceTeamJoin } from './ExperienceTeamJoin';
import { ExperienceTeamCreate } from './ExperienceTeamCreate';

enum Option {
  Join = 'JOIN',
  Create = 'CREATE',
}

/** Route `/experience/:experienceId/team` */
export function ExperienceTeamStep() {
  const { experience: initialExperience } = useCurrentExperience();
  const [selected, setSelected] = useState<Option>(Option.Join);
  const { items: cart, setMany: setManyCart } = useShoppingCartState();
  const { openRegistrationForm, openSwagBagsForm, openAssetsForm } = useOpenExperiencePages();
  const { firstName } = useCurrentUser();

  const changeSelected = (option: Option) => {
    setSelected(option);
  };

  const selectJoin = () => {
    changeSelected(Option.Join);
  };

  const selectCreate = () => {
    changeSelected(Option.Create);
  };

  const redirectTicketing = () => {
    if (initialExperience?.experienceType === 'Ticketing') {
      openAssetsForm(initialExperience?.id ?? '');
    } else {
      openSwagBagsForm();
    }
  };

  /** Clear all the registration option team's selection and continue the flow. */
  const onDismiss = () => {
    const registrations = cart
      // Filter by registration options only.
      .filter(isShoppingCartRegistrationItem)
      // Remove from the registration the selected team.
      .map(registration => omit(registration, 'team'));

    setManyCart(registrations);
    redirectTicketing();
  };

  /** Select one team and continue the flow. */
  const onSelected = () => {
    redirectTicketing();
  };

  /** Create one team and continue the flow. */
  const onCreated = () => {
    redirectTicketing();
  };

  useEffect(() => {
    if (initialExperience?.experienceType === 'Ticketing') {
      openRegistrationForm(initialExperience?.id ?? '');
    }
  }, [initialExperience, openRegistrationForm]);

  return (
    <ExperiencePurchaseLayout>
      <Typography fontWeight="lighter" align="center" variant="h5">
        Great, {firstName}! Now choose how you want to participate.
      </Typography>

      <Container>
        <TabContainer>
          <TabItem active={selected === Option.Join} onClick={selectJoin}>
            Join a team
          </TabItem>

          <TabItem active={selected === Option.Create} onClick={selectCreate}>
            Create a team
          </TabItem>
        </TabContainer>

        <TabContext value={selected}>
          <TabPanel value={Option.Join}>
            <ExperienceTeamJoin onSelected={onSelected} onDismiss={onDismiss} />
          </TabPanel>

          <TabPanel value={Option.Create}>
            <ExperienceTeamCreate onCreated={onCreated} onDismiss={onDismiss} />
          </TabPanel>
        </TabContext>
      </Container>
    </ExperiencePurchaseLayout>
  );
}
