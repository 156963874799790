/* eslint-disable import/order */
// eslint-disable-next-line import/no-unresolved

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, CardMedia, CircularProgress, Grid, Link, Theme, Typography } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { ParticipatingType } from 'src/constants/participating';
import { MODALS as APP_MODALS } from 'src/constants/modals';
import { ConfirmationJoiningTeamDialog } from 'src/modals';
import { useTeamLandingData } from 'src/hooks/team';
import {
  GradientDivider,
  GradientLinearProgress,
  LandingButton,
  MainAvatar,
  ParticipatingCard,
} from 'src/components/shared';

import { buildUrl, formatMoney, getUserFullName } from '@vizsla/utils';
import { HtmlRenderer, ModalFAQ, ShareSocialMedia } from '@vizsla/components';
import { useAppAuth, useCurrentUser, useModal } from '@vizsla/hooks';
import { LANDING_ROUTES, MODALS } from '@vizsla/constants';
import { AttendeeListResponse, USER_ATTENDEE_EXPERIENCE } from '@vizsla/graphql';

import { GallerySection, PeopleSection } from './components';
import maraphon from './assets/maraphon.jpg';
import { TeamLeaderBoard } from '../TeamLeaderBoard/TeamLeaderBoard';
import { CampaignFaqAllocation } from '@vizsla/types';
import { usePublicCampaignFAQs } from 'src/hooks/campaign';
import { MembersRankedModal } from './components/MembersRankedModal';
import { TeamsRankedModal } from './components/TeamsRankedModal';
import { useTeamRankingTables } from './hooks/useTeamRankingTables';

// capture team id from url

const TEMP_STYLES = {
  headerBackground: '#fff', // header background
  accentColor: '#EA4235', // buttons, top border below image
  textLinksColor: '#EA4235', // text links color
  pageBackground: '#fff',
  headerLogoAlign: 'left', // left | center
  heroImage: maraphon,
};

interface StylesParams {
  accentColor: string;
  pageBackground: string;
}

const useStyles = makeStyles<Theme, StylesParams>({
  root: {
    height: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    color: '#34b9ff',
  },

  content: {
    gridArea: 'content',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  contentBox: {
    width: '100%',
    borderTop: ({ accentColor }) => `8px solid ${accentColor}`,
    background: ({ pageBackground }) => pageBackground,
    marginBottom: '25px',
    display: 'flex',
    justifyContent: 'center',
  },

  boldText: {
    fontWeight: 'bold',
  },
});

interface UserAttendeeExperience {
  attendeesList: AttendeeListResponse;
}
export const TeamLanding: React.FC = () => {
  const styles = TEMP_STYLES;
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const { isAuthenticated } = useAppAuth();

  const { teamId } = useParams();
  const { openModal, isOpen } = useModal(MODALS.JOINING_TEAM_MODAL);
  const {
    openModal: openTeamsModal,
    isOpen: isOpenTeamsModal,
    closeModal: closeTeamsModal,
  } = useModal(MODALS.TEAMS_MODAL);
  const {
    openModal: openTeamsRankedModal,
    isOpen: isOpenTeamsRankedModal,
    closeModal: closeTeamsRankedModal,
  } = useModal(MODALS.TEAMS_RANKED_MODAL);
  const { openModal: openModalShare } = useModal(MODALS.SHARE_SOCIAL_MEDIA);
  const { user, loading: loadingUser } = useCurrentUser();
  const {
    isOpen: isOpenFAQ,
    closeModal: closeFAQModal,
    openModal: openFAQModal,
  } = useModal(MODALS.FAQ_MODAL);
  const [activeTab, setActiveTab] = React.useState<CampaignFaqAllocation>(
    CampaignFaqAllocation.Attendee,
  );
  const handleTabSwitch = (_: React.ChangeEvent<any>, newValue: CampaignFaqAllocation) => {
    setActiveTab(newValue);
  };

  const { campaign, teamCaptain, team, teamPercentage, members, experience } =
    useTeamLandingData(teamId);
  const { loading: loadingFAQ, campaignFaqExperience } = usePublicCampaignFAQs(
    CampaignFaqAllocation.Donor,
    experience?.id ?? '',
  );
  const hasFAQs =
    campaignFaqExperience?.[CampaignFaqAllocation.Attendee]?.length ||
    campaignFaqExperience?.[CampaignFaqAllocation.Donor]?.length;

  const openShareModal = () => {
    openModalShare(MODALS.SHARE_SOCIAL_MEDIA);
  };

  const [findAttendeeByFilter] = useLazyQuery<UserAttendeeExperience>(USER_ATTENDEE_EXPERIENCE);
  const [hasMembership, setHasMembership] = useState(false);
  const raised = formatMoney(team?.totalRaised);
  const goal = formatMoney(team?.fundraiserGoal);
  const { currentTeamRank, teamsModalTable, teamsRankedModalTable, rankedTeams } =
    useTeamRankingTables({
      experience,
      team,
    });

  const membershipValidation = React.useCallback(async () => {
    if (loadingUser) return;

    if (!user?.id || !experience) {
      setHasMembership(false);
      return;
    }

    const response = await findAttendeeByFilter({
      variables: {
        userId: user.id,
        experienceId: experience?.id,
      },
    });

    setHasMembership(!!response?.data?.attendeesList?.items[0]?.teamMembership);
  }, [user, experience, loadingUser, findAttendeeByFilter]);

  useEffect(() => {
    membershipValidation().catch(console.error);
  }, [isOpen, membershipValidation]);

  const openJoinTeamModal = () => {
    if (isAuthenticated) {
      openModal(MODALS.JOINING_TEAM_MODAL, { team });
      return;
    }
    const onComplete = () => {
      openModal(MODALS.JOINING_TEAM_MODAL, { team });
    };
    openModal(APP_MODALS.LOGIN_MODAL, { onComplete });
  };

  const openDonate = () => {
    const donateURL = buildUrl(LANDING_ROUTES.TEAM_DONATION, { teamId });
    navigate(donateURL);
  };

  if (!campaign || !team) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress style={{ width: '65px', height: '65px' }} color="secondary" />
      </Box>
    );
  }
  const STORY_TEXT = `<span style="color: #2A394A; font-family: Inter, sans-serif; font-size: 16px; line-height: 26px; letter-spacing: -0.08px;">${
    team?.teamMissionDescription || ''
  }</span>`;

  return (
    <div className={classes.root}>
      <ModalFAQ
        isOpen={isOpenFAQ}
        closeModal={closeFAQModal}
        theme={{}}
        campaingFaqExperience={campaignFaqExperience}
        activeTab={activeTab}
        handleTabSwitch={handleTabSwitch}
        CampaignFaqAllocation={CampaignFaqAllocation}
      />
      <MembersRankedModal
        open={isOpenTeamsModal}
        closeModal={closeTeamsModal}
        title={`${team.members?.count} members`}
        team={team}
        render={teamsModalTable}
      />
      <TeamsRankedModal
        open={isOpenTeamsRankedModal}
        closeModal={closeTeamsRankedModal}
        title={`Ranked ${currentTeamRank?.rank} of ${rankedTeams?.length}`}
        team={team}
        render={teamsRankedModalTable}
      />
      <main className={classes.content}>
        <div>
          <CardMedia
            component="img"
            height="450"
            image={
              team?.teamPhoto?.downloadUrl ||
              experience?.experienceHeaderImage?.downloadUrl ||
              undefined
            }
            alt="campaign image"
          />
          <div className={classes.contentBox}>
            <Grid container justifyContent="center" spacing={6} sx={{ maxWidth: '1440px' }}>
              <Grid
                marginTop="-60px"
                item
                xs={6}
                md={3}
                container
                flexDirection="column"
                alignItems="center"
              >
                <Grid item>
                  <MainAvatar avatarUrl={team?.avatar?.downloadUrl || ''} />
                </Grid>

                {teamCaptain && (
                  <ParticipatingCard
                    type={ParticipatingType.teamCamptain}
                    name={getUserFullName(teamCaptain?.user)}
                    fundraisingGoal={teamCaptain?.fundraising?.goal ?? teamCaptain?.fundraiserGoal}
                    totalRaised={teamCaptain?.fundraising?.raised ?? teamCaptain?.totalRaised}
                    img={teamCaptain?.user?.avatar?.downloadUrl || undefined}
                  />
                )}

                <ParticipatingCard
                  type={ParticipatingType.benefitingCharity}
                  name={campaign?.name}
                  fundraisingGoal={campaign?.fundraisingGoal}
                  totalRaised={campaign?.totalRaised}
                  img={
                    experience?.experienceLogoImage?.downloadUrl ||
                    campaign?.logo?.downloadUrl ||
                    undefined
                  }
                  experienceId={experience?.id ?? ''}
                />
              </Grid>
              <Grid marginTop={4} item xs={10} md={6} container flexDirection="column">
                <Grid container item spacing={3}>
                  <Grid container item xs={12} md={8} flexDirection="column">
                    <Grid item marginTop="10px">
                      <Typography variant="h1">{team.name}</Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item>
                        <Link
                          onClick={() => openTeamsModal(MODALS.TEAMS_MODAL)}
                          sx={{ color: `${styles.textLinksColor}`, cursor: 'pointer' }}
                        >
                          {team.members?.count} members
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link
                          onClick={() => openTeamsRankedModal(MODALS.TEAMS_RANKED_MODAL)}
                          sx={{ color: `${styles.textLinksColor}`, cursor: 'pointer' }}
                        >
                          {currentTeamRank?.rank
                            ? `Ranked ${currentTeamRank?.rank} of ${rankedTeams?.length}`
                            : ''}
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid item maxWidth="375px" width="100%" marginTop={2}>
                      <GradientLinearProgress
                        height="16px"
                        variant="determinate"
                        value={teamPercentage}
                      />
                    </Grid>
                    <Grid item marginTop={2}>
                      <Typography variant="body2" sx={{ fontSize: '21px', lineHeight: '20px' }}>
                        <span className={classes.boldText}>{raised}</span> Raised of a goal of
                        {` ${goal}`}
                      </Typography>
                    </Grid>
                  </Grid>

                  {loadingFAQ ? (
                    <React.Fragment />
                  ) : (
                    <Grid
                      xs={12}
                      md={4}
                      item
                      container
                      direction={['row', 'row', 'column']}
                      spacing={2}
                      marginTop={2}
                    >
                      <Grid item>
                        <LandingButton
                          themeColor={styles.accentColor}
                          contained
                          onClick={openDonate}
                        >
                          DONATE
                        </LandingButton>
                      </Grid>
                      <Grid item>
                        {!hasMembership && (
                          <LandingButton
                            themeColor={styles.accentColor}
                            onClick={openJoinTeamModal}
                          >
                            JOIN TEAM
                          </LandingButton>
                        )}
                      </Grid>
                      {isOpen && <ConfirmationJoiningTeamDialog />}
                      <Grid item>
                        <LandingButton themeColor={styles.accentColor} onClick={openShareModal}>
                          SHARE
                        </LandingButton>
                        <ShareSocialMedia />
                      </Grid>
                      {hasFAQs ? (
                        <Grid item>
                          <LandingButton
                            onClick={() => openFAQModal(MODALS.FAQ_MODAL)}
                            themeColor={styles.accentColor}
                          >
                            FAQ
                          </LandingButton>
                          <ShareSocialMedia />
                        </Grid>
                      ) : (
                        <React.Fragment />
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid width="100%" item marginTop="50px">
                  <GradientDivider color="#e9e9e9" />
                </Grid>
                <Grid item marginTop="50px">
                  <Typography variant="h3" marginBottom={1}>
                    {team?.teamMissionTitle}
                  </Typography>
                  <HtmlRenderer html={STORY_TEXT} />
                </Grid>
                <Grid item marginTop="50px">
                  <GallerySection images={team?.gallery?.items ?? []} color={styles.accentColor} />
                </Grid>
                <Grid item marginTop="50px">
                  <GradientDivider color={styles.accentColor} />
                </Grid>
                <Grid item marginTop="50px">
                  <TeamLeaderBoard />
                </Grid>
                <Grid item marginTop="50px">
                  <PeopleSection members={members} styles={styles} teamId={teamId ?? ''} />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </main>
    </div>
  );
};
