import React from 'react';

import { useCurrentUser, useShoppingCartState, useExperienceId } from '@vizsla/hooks';
import { useExperienceLandingQuery } from '@vizsla/graphql';

import { ExperiencePurchaseLayout } from 'src/layouts';
import {
  useOpenExperiencePages,
  useSelectedExperience,
  useVerifyAvailabilityOptions,
  useVerifyAvailabilityTicketing,
} from 'src/hooks';

import { StepTitle } from './ExperienceOptionsStep.style';
import { NotAvailable } from './NotAvailable/NotAvailable';
import { RegistrationsOptionForm } from './RegistrationOptionsForm/RegistrationsOptionForm';
import { TicketingOptionForm } from './TicketingOptionForm/TicketingOptionForm';
import { useEventStoreContext } from '../../providers/EventStoreProvider';

const EXPERIENCE_TYPE = 'Ticketing';

export function ExperienceOptionsStep() {
  const [isEarlyBirdCodeValid, setIsEarlyBirdCodeValid] = React.useState<boolean>(false);
  const { setRegistration } = useEventStoreContext();
  const { firstName } = useCurrentUser();
  const experience = useSelectedExperience(state => state.data);
  const experienceId = useExperienceId();
  const { data: experienceData } = useExperienceLandingQuery({
    variables: { id: experienceId },
  });
  const clean = useShoppingCartState(state => state.clean);
  const { openAttendeesForm } = useOpenExperiencePages();
  const { optionsAvailable, isEarlyBirdCodeValidOptions } =
    useVerifyAvailabilityOptions(experienceData);
  const { ticketingsAvailable, isEarlyBirdCodeValidTicketing } =
    useVerifyAvailabilityTicketing(experienceData);
  const handleSubmit = () => openAttendeesForm(experienceId as string);

  React.useEffect(() => {
    // Clean the shopping cart state on mount
    setRegistration(false);
    clean();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clean]);

  const verifyAvailability = React.useMemo(() => {
    if (EXPERIENCE_TYPE === experienceData?.experience?.experienceType) {
      setIsEarlyBirdCodeValid(isEarlyBirdCodeValidTicketing);
      return ticketingsAvailable;
    }
    setIsEarlyBirdCodeValid(isEarlyBirdCodeValidOptions);
    return optionsAvailable;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsAvailable, ticketingsAvailable]);

  if (!verifyAvailability.available && experienceData && !isEarlyBirdCodeValid) {
    return (
      <React.Fragment>
        <NotAvailable
          handleChangeAccess={setIsEarlyBirdCodeValid}
          experience={experienceData.experience}
          date={verifyAvailability.date}
        />
      </React.Fragment>
    );
  }

  return (
    <ExperiencePurchaseLayout>
      <StepTitle variant="h5">Right on, {firstName}! Next, select your option.</StepTitle>
      {EXPERIENCE_TYPE === experienceData?.experience?.experienceType ? (
        <TicketingOptionForm
          onSubmit={handleSubmit}
          experienceId={experience?.id}
          earlyBirdValidated={isEarlyBirdCodeValid}
        />
      ) : (
        <RegistrationsOptionForm
          onSubmit={handleSubmit}
          experienceId={experience?.id}
          earlyBirdValidated={isEarlyBirdCodeValid}
        />
      )}
    </ExperiencePurchaseLayout>
  );
}
