import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { Button as ButtonBase } from '@vizsla/components';
import { PaletteColor } from '@vizsla/theme';

const SPACING_UNIT = 3;

/// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  //gap: ${p => p.theme.spacing(SPACING_UNIT)};
`;

/// Header

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

/// HeaderTitle

export const HeaderTitle = styled(Typography)``;

HeaderTitle.defaultProps = {
  variant: 'h6',
};

/// Buttons

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: ${p => p.theme.spacing(2)};
`;

/// Button

export const Button = styled(ButtonBase)`
  margin: 0;
`;

export const StripeBillingAddressContainer = styled.div`
  padding: 1rem 2rem;
`;

export const StripePaymentDetails = styled.div`
  padding: 2rem;
`;

export const DonationContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-height: 4rem;
  min-height: 10rem;
  border-radius: 8px;
  outline: solid 1px #c0c4c9;
  margin: 1rem 0;
`;

export const DonationType = styled(Typography)`
  font-size: 1.2rem;
  line-height: 1;
`;

export const DonationAmount = styled(Typography)`
  font-weight: bold;
  font-size: 2.5rem;
`;

export const ProtectedByContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

/// ProtectedByLabel

export const ProtectedByLabel = styled(Typography)`
  color: ${PaletteColor.GrayText};
`;

ProtectedByLabel.defaultProps = {
  variant: 'body2',
};

/// ProtectedByLogo

export const ProtectedByLogo = styled.img`
  object-fit: contain;
  height: ${p => p.theme.spacing(3.5)};
  margin: '10px';
`;
