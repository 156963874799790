import React from 'react';
import { Grid, Typography, typographyClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PaletteColor } from '@vizsla/theme';
import { CampaignTeamMember } from '@vizsla/graphql';
import { formatDate } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';
import { TableColumn, TableData, UserAvatarFullNameInline } from '@vizsla/components';

const useStyles = makeStyles({
  subTitle: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.GrayText,
    },
  },
  tableRowAction: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.DefaultText,
    },
  },
});

interface MembersTableProps {
  members: CampaignTeamMember[] | null | undefined;
  styles?: any;
  loading?: boolean;
  hideTitle?: boolean;
}

export const MembersTable: React.FC<MembersTableProps> = ({
  styles,
  members,
  loading = false,
  hideTitle = true,
}) => {
  const classes = useStyles();
  const { pageBackgroundSecondColor, accentColor } = styles;

  const columns: Array<TableColumn<CampaignTeamMember>> = [
    {
      key: 'user',
      title: 'user',
      render: (member: CampaignTeamMember) => {
        return (
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
            sx={{
              backgroundColor: pageBackgroundSecondColor || PaletteColor.White,
              borderColor: accentColor || PaletteColor.Black,
            }}
          >
            <Grid item>
              <UserAvatarFullNameInline
                user={member.attendee?.user}
                additionalCaption={
                  <Typography variant="caption">
                    {formatDate(
                      member.attendee?.user?.createdAt,
                      DateFormatPatterns.shortMonthDayYear,
                    )}
                  </Typography>
                }
                inline
              />
            </Grid>
            <Grid item>
              <Typography variant="caption" className={classes.tableRowAction}>
                {member.attendee?.registrationOption?.name}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Typography variant="caption" className={classes.subTitle}>
        Team Members
      </Typography>
      <TableData
        hideTitle={hideTitle}
        dataSource={members ?? []}
        columns={columns}
        loading={loading}
      />
    </React.Fragment>
  );
};
