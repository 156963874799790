import React, { Fragment, useCallback, useEffect } from 'react';
import { Check as CheckIcon } from '@mui/icons-material';
import { Grid, CardMedia } from '@mui/material';
import { google } from 'calendar-link';
import { useAuth } from '@8base/react-auth';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';

import { Button, SocialNetwork } from '@vizsla/components';
import { useAttendeeIdByEmailLazyQuery } from '@vizsla/graphql';

import { useCurrentPublicCampaign } from 'src/hooks/campaign';
import { useIntegrationData } from 'src/routes/CheckoutPage/CheckoutPageOnePage';

import { Headline, MessageThankYouLanding, DonationInformation } from './components';
import {
  CheckContainer,
  Container,
  Divider,
  Section,
  SubTitle,
  Title,
  Text,
  SocialContainer,
  Logo,
  CenterContent,
} from './ThanksPage.styles';
import { useEventStoreContext } from '../../providers/EventStoreProvider';

interface Props {
  title: string;
  subtitle: string[];
  uri?: string;
}

const INTEGRATIONS_TYPE_LABEL = {
  DOUBLE_THE_DONATION: 'Double the Donation',
  AMPLY: 'Amply',
};

const useStyles = makeStyles({
  questionSection: {
    maxWidth: '35rem',
  },
  questionText: {
    textAlign: 'center',
    marginTop: '1rem',
  },
  sectionButtons: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const removeThanks = (url: string): string => url.replace(/\/thanks$/, '');

export function ThanksPage(props: Props) {
  const { donation, registration } = useEventStoreContext();
  const { authClient } = useAuth();
  const classes = useStyles();
  const { campaignTemplates, campaign, organization, experiences } = useCurrentPublicCampaign();
  const thankYouTemplate = campaignTemplates?.templates?.items.filter(
    template => template.templateType?.type === 'THANK_YOU',
  )[0];
  const selectIntegrationResponse = state => ({ items: state.items });
  const { items: integrationResponseItems } = useIntegrationData(selectIntegrationResponse);
  const currentURL = window.location.href;
  const redirectURL = removeThanks(currentURL);

  const thankYouTemplateData = thankYouTemplate?.structure?.appSchema?.theme;
  const thankYouMessage = thankYouTemplateData?.thankYouMessage || '';
  const isThankYouMessageVisible = thankYouTemplateData?.isThankYouMessageVisible || false;
  const contactEmail = campaign?.contactEmail || '';
  const contactName = organization?.name || '';
  const contactPhone = campaign?.contactPhone?.number
    ? `+${campaign?.contactPhone?.code} ${(campaign?.contactPhone?.number).replace(
        /(\d{3})(\d{3})(\d{4})/,
        '$1-$2-$3',
      )}`
    : '';
  const experienceName = props.title;

  const experienceCurrent = experiences.filter(experience => experience.name === experienceName);
  const experienceHeaderImage =
    experienceCurrent[0]?.experienceHeaderImage === null ||
    experienceCurrent[0]?.experienceHeaderImage === undefined
      ? {}
      : experienceCurrent[0].experienceHeaderImage;

  const imagesExperience = [experienceHeaderImage];

  const PAPER_BLOCK_SIZE = 215;
  const IMAGE_SIZE = PAPER_BLOCK_SIZE - 16;
  const userEmail = authClient.getTokenInfo().email;

  const [executeQuery, { data: attendee, loading }] = useAttendeeIdByEmailLazyQuery({
    variables: { email: userEmail, experienceId: experienceCurrent[0]?.id || '' },
  });

  const callExecuteQuery = useCallback(() => {
    executeQuery({
      variables: { email: userEmail, experienceId: experienceCurrent[0]?.id || '' },
      fetchPolicy: 'network-only',
      onCompleted: data => {
        const attendeeId = data?.experience?.attendees?.items[0]?.id;
        if (!attendeeId) {
          setTimeout(callExecuteQuery, 100);
        }
      },
    });
  }, [executeQuery, userEmail, experienceCurrent]);

  useEffect(() => {
    if (registration) {
      callExecuteQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const attendeeId = attendee?.experience?.attendees?.items[0]?.id;

  const ticketing = experienceCurrent[0].experienceType === 'Ticketing';

  const goToDashboard = () => {
    window.open(`${process.env.REACT_APP_CONSUMER_APP_URL}/myimpact`);
  };

  const goToFundraiserPage = () => {
    if (attendeeId) {
      window.open(`${process.env.REACT_APP_CONSUMER_APP_URL}/attendee/${attendeeId}/content`);
    }
  };

  const addToCalendar = () => {
    const event: any = {
      title: experienceCurrent[0]?.name,
      description: experienceCurrent[0]?.name,
      start: experienceCurrent[0]?.startDate,
      end:
        experienceCurrent[0]?.endDate ||
        new Date(experienceCurrent[0]?.startDate).getTime() + 60 * 60000,
    };
    window.open(google(event), 'calendar');
  };

  return (
    <Container>
      {props.uri && <Logo src={props.uri} alt={props.title} />}

      <Grid container spacing={3}>
        {imagesExperience &&
          imagesExperience?.map((image: any) => {
            if (!image.downloadUrl) return '';

            return (
              <CardMedia
                component="img"
                height={IMAGE_SIZE}
                image={image.downloadUrl}
                alt={image.filename}
                key={image.id}
              />
            );
          })}
      </Grid>

      <Section className={classes.questionSection}>
        {props.subtitle.map((text, index) => (
          <SubTitle key={text}>{text}</SubTitle>
        ))}
        <Title>{props.title}</Title>
      </Section>

      <CheckContainer>
        <CheckIcon fontSize="inherit" />
      </CheckContainer>

      <Headline />

      {donation && (
        <MessageThankYouLanding
          thankYouMessage={thankYouMessage}
          isThankYouMessageVisible={isThankYouMessageVisible}
        />
      )}

      {/* {donation && (
        <Fragment>
          <Divider />

          <CenterContent>
            <DonationInformation
              integration={INTEGRATIONS_TYPE_LABEL[integrationResponseItems[0]?.integrationType]}
              company={integrationResponseItems[0]?.integrationCompany}
              amount={integrationResponseItems[0]?.integrationDonationAmount}
            />
          </CenterContent>
        </Fragment>
      )} */}

      <Divider />

      <CenterContent>
        {!ticketing && registration && (
          <Fragment>
            <Button
              variant="contained"
              onClick={goToDashboard}
              style={{ borderRadius: 30 }}
              className={classes.sectionButtons}
            >
              My Dashboard
            </Button>
            <Button
              variant="contained"
              loading={!attendeeId || loading}
              onClick={goToFundraiserPage}
              style={{ borderRadius: 30 }}
              className={classes.sectionButtons}
            >
              Set Up Fundraising Page
            </Button>
            <Button
              variant="contained"
              onClick={addToCalendar}
              style={{ borderRadius: 30 }}
              className={classes.sectionButtons}
            >
              Add to Calendar
            </Button>
          </Fragment>
        )}
        {!ticketing && donation && !registration && (
          <Button
            variant="contained"
            onClick={goToDashboard}
            style={{ borderRadius: 30 }}
            className={classes.sectionButtons}
          >
            My Dashboard
          </Button>
        )}
        {ticketing && (
          <Fragment>
            <Button
              variant="contained"
              onClick={goToDashboard}
              style={{ borderRadius: 30 }}
              className={classes.sectionButtons}
            >
              My Dashboard
            </Button>
            <Button
              variant="contained"
              onClick={addToCalendar}
              style={{ borderRadius: 30 }}
              className={classes.sectionButtons}
            >
              Add to Calendar
            </Button>
          </Fragment>
        )}
      </CenterContent>

      <Fragment>
        <Divider />
        <Section className={classes.questionSection}>
          <Title>Spread the Word</Title>

          <SocialContainer>
            <SocialNetwork
              type="facebook"
              link={`https://facebook.com/sharer/sharer.php?u=${redirectURL}`}
              brandingColor
            />
            <SocialNetwork
              type="twitter"
              link={`https://twitter.com/intent/tweet?text=${redirectURL}`}
              brandingColor
            />
            <SocialNetwork type="mail" link={`mailto:?body=${redirectURL}`} brandingColor />
          </SocialContainer>
        </Section>
        <Divider />
      </Fragment>

      <Section className={classes.questionSection}>
        <Title>Have Questions?</Title>
        <Text className={classes.questionText}>
          {`If you have any questions, contact ${contactName} at ${contactEmail} or call ${contactPhone}`}
        </Text>
      </Section>
    </Container>
  );
}
