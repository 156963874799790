import React from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  Avatar,
  Box,
  Popover,
  ClickAwayListener,
  Divider,
  Typography,
  List,
  ListItemButton,
  ListItem,
  Theme,
  Skeleton,
  ListItemText,
  ListItemIcon,
  SxProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { CURRENT_USER_QUERY } from '@vizsla/graphql';
import { useAppAuth } from '@vizsla/hooks';
import { IconName } from '@vizsla/components';
import { BorderRadius, PaletteColor, Shade } from '@vizsla/theme';

import { CustomListItemIcon } from './CustomListItemIcon';

interface MenuItem {
  label: string;
  linkTo: string;
  name: IconName;
  type: string;
  styles?: SxProps<Theme>;
}

const CONSUMER_URL = `${process.env.REACT_APP_CONSUMER_APP_URL}`;

const menuItems: MenuItem[] = [
  {
    label: 'Profile',
    linkTo: '/my-profile',
    name: 'AccountCircleOutlined',
    type: 'tab',
  },
  {
    label: 'My Impact',
    linkTo: '/myimpact',
    name: 'DashboardOutlined',
    type: 'tab',
  },
  {
    label: 'Log out',
    linkTo: '/',
    name: 'ExitToAppOutlined',
    type: 'logout',
    styles: {
      transform: 'rotate(180deg)',
      marginRight: '10px',
      height: '20px',
      minWidth: '14px',
      color: '#DF3B3B',
    },
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  logOutLink: {
    color: PaletteColor.ErrorMain,
  },
  avatar: {
    backgroundColor: PaletteColor.SecondaryMain,
  },
  popoverPaper: {
    borderRadius: BorderRadius.M,
    marginTop: theme.spacing(2),
    minWidth: 250,
  },
  popoverBlock: {
    padding: theme.spacing(2, 0, 2, 0),
  },
}));

export const ProfilePopover: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { experienceId } = useParams();

  const INITIAL_ROUTE_CONSUMER = `/experience/${experienceId}`;

  function getRedirectRoute(type: string, id: string) {
    return `/${type}/${id}`;
  }

  const { logout } = useAppAuth();

  const client = useApolloClient();

  const [anchorEl, setAnchorEl] = React.useState<Element | null>();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );
  const navigate = useNavigate();

  const { loading, data } = useQuery(CURRENT_USER_QUERY);

  const isOpened = Boolean(anchorEl);

  const handleLogout = React.useCallback(async () => {
    const pathSegments = location.pathname.split('/');

    // Extract the type (experience or fundraiser) and id
    const type = pathSegments[1]; // e.g., 'experience' or 'fundraiser'
    const id = pathSegments[2];

    const route = getRedirectRoute(type, id);

    await logout();
    // eslint-disable-next-line no-void
    await client.clearStore();
    navigate(route);
  }, [logout, client]);

  const redirect = (path: string) => {
    window.open(`${CONSUMER_URL}${path}` ?? INITIAL_ROUTE_CONSUMER, '_blank');
    setAnchorEl(null);
  };

  return (
    <Box>
      {loading ? (
        <Skeleton animation="wave" variant="circular">
          <Avatar />
        </Skeleton>
      ) : (
        <React.Fragment>
          <Avatar
            onClick={handleClick}
            sx={{ cursor: 'pointer' }}
            className={classes.avatar}
            src={data?.user?.avatar?.downloadUrl}
          />
          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorEl}
            open={isOpened}
            classes={{ paper: classes.popoverPaper }}
          >
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <Box>
                <Box className={classes.popoverBlock} textAlign="center">
                  <Box>
                    <Avatar
                      sx={{ margin: '0 auto' }}
                      className={classes.avatar}
                      src={data?.user?.avatar?.downloadUrl}
                    />
                  </Box>

                  <Box>
                    <Typography variant="subtitle2">
                      {data?.user?.firstName} {data?.user?.lastName}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="caption" color={Shade.Gray[400]}>
                      {data?.user?.email}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.popoverBlock}>
                  <List disablePadding>
                    {menuItems.map(item => (
                      <ListItem
                        disablePadding
                        key={item.label}
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          item.type === 'logout' ? handleLogout() : redirect(item.linkTo ?? '/');
                        }}
                      >
                        <ListItemButton
                          sx={{
                            padding: '0.3rem 1.2rem',
                          }}
                        >
                          <ListItemIcon
                            sx={
                              item.type === 'logout'
                                ? item.styles
                                : {
                                    minWidth: '26px',
                                  }
                            }
                          >
                            <CustomListItemIcon
                              name={item.name}
                              variant="filled"
                              color="inherit"
                              fontSize="default"
                              viewBox="0 0 24 24"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{ style: { fontSize: '13px' } }}
                            primary={item.label}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </ClickAwayListener>
          </Popover>
        </React.Fragment>
      )}
    </Box>
  );
};
