var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { InboxTypeFilterOption } from '@vizsla/types';
import { useCreateInboxMutation, useCurrentUserQuery, useInboxesQuery, useInboxFilters, useInboxMessageAddedSubscription, useRemoveInboxMutation, } from '../hooks';
import { InboxEvents, useEventBus } from '../inbox.events';
export const InboxContext = React.createContext(null);
export const InboxContextProvider = ({ children }) => {
    const { publish } = useEventBus();
    const { user, loading: userIsLoading } = useCurrentUserQuery();
    const { data: inboxes, loading: inboxesLoading, refetch: refetchInboxesQuery, } = useInboxesQuery();
    const [selectedInbox, selectInbox] = React.useState();
    const createInbox = useCreateInboxMutation();
    const removeInbox = useRemoveInboxMutation({
        inbox: selectedInbox,
        onRemoveCompleted: () => selectInbox(undefined),
    });
    const [selectedInboxIsDraft, setSelectedInboxIsDraft] = React.useState(false);
    const handleNewInboxAdded = React.useCallback((inbox, message) => {
        publish(InboxEvents.MessageReceived, { message, inbox });
        refetchInboxesQuery();
    }, [publish, refetchInboxesQuery]);
    useInboxMessageAddedSubscription({ onNewMessage: handleNewInboxAdded });
    const createDraftInbox = React.useCallback((users) => {
        if (!user)
            return;
        const existingInbox = inboxes === null || inboxes === void 0 ? void 0 : inboxes.find(inbox => {
            var _a;
            // only direct messages can be drafts
            if (inbox.type !== InboxTypeFilterOption.DirectMessage)
                return false;
            // only inboxes with the current user and the selected user can be drafts
            const userIds = new Set(users.map(u => u.id).concat(user.id));
            return (_a = inbox.recipients) === null || _a === void 0 ? void 0 : _a.items.every(recipient => userIds.has(recipient.id));
        });
        if (!existingInbox) {
            const inbox = {
                recipients: { count: 0, groups: [], items: [user, ...users] },
                inboxMessages: { count: 0, groups: [], items: [] },
                type: InboxTypeFilterOption.DirectMessage,
                createdBy: user,
                createdAt: new Date().toISOString(),
            };
            setSelectedInboxIsDraft(true);
            selectInbox(inbox);
            return;
        }
        selectInbox(existingInbox);
        setSelectedInboxIsDraft(false);
    }, [inboxes, user]);
    const discardDraftInbox = React.useCallback(() => {
        selectInbox(undefined);
        setSelectedInboxIsDraft(false);
    }, []);
    const saveDraftInbox = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!selectedInbox)
            throw new Error('No selected inbox');
        if (!selectedInboxIsDraft)
            return selectedInbox;
        const { recipients, type } = selectedInbox;
        const inbox = {
            recipients: { connect: (_a = recipients === null || recipients === void 0 ? void 0 : recipients.items.map(({ id }) => ({ id }))) !== null && _a !== void 0 ? _a : [] },
            type,
        };
        const newInbox = yield createInbox.mutation(inbox);
        setSelectedInboxIsDraft(false);
        selectInbox(newInbox);
        return newInbox;
    }), [createInbox, selectedInbox, selectedInboxIsDraft]);
    const { filters } = useInboxFilters();
    useEffect(() => {
        /**
         * When the filters change, we want to deselect the inbox
         */
        if (!selectedInbox)
            return;
        selectInbox(undefined);
    }, [filters]);
    return (_jsx(InboxContext.Provider, Object.assign({ value: {
            inboxes,
            inboxesLoading: inboxesLoading || userIsLoading,
            selectedInbox,
            selectInbox,
            selectedInboxIsDraft,
            createDraftInbox,
            discardDraftInbox,
            createInbox,
            removeInbox,
            saveDraftInbox,
        } }, { children: children })));
};
export const useInboxContext = () => {
    const context = React.useContext(InboxContext);
    if (!context) {
        throw new Error('useInboxContext must be used within InboxContext');
    }
    return context;
};
