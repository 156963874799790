/* eslint-disable import/order */
// eslint-disable-next-line import/no-unresolved

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, CardMedia, CircularProgress, Grid, Theme, Typography } from '@mui/material';
import { useFundraiserLandingData } from 'src/hooks/fundraiser';
import { useCurrentPublicCampaign, usePublicCampaignFAQs } from 'src/hooks/campaign';
import { MODALS } from 'src/constants/modals';
import { GradientLinearProgress, MainAvatar, ParticipatingCard } from 'src/components/shared';
import { ParticipatingType } from 'src/constants/participating';

import {
  GradientDivider,
  HtmlRenderer,
  LandingButton,
  ModalFAQ,
  ShareSocialMedia,
} from '@vizsla/components';
import { useModal } from '@vizsla/hooks';
import { LANDING_ROUTES } from '@vizsla/constants';
import { buildUrl, formatMoney, getUserFullName } from '@vizsla/utils';

import { GallerySection, PeopleSection } from './components';
import HEADER_IMAGE from './assets/maraphon.jpg';
import { CampaignFaqAllocation } from '@vizsla/types';

const TEMP_STYLES = {
  headerBackground: '#fff', // header background
  accentColor: '#EC308C', // buttons, top border below image
  textLinksColor: '#EC308C', // text links color
  pageBackground: '#fff',
  headerLogoAlign: 'left', // left | center
  heroImage: HEADER_IMAGE,
};

interface StylesParams {
  accentColor: string;
  pageBackground: string;
}

const useStyles = makeStyles<Theme, StylesParams>({
  root: {
    height: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    color: '#34b9ff',
  },

  content: {
    gridArea: 'content',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  contentBox: {
    width: '100%',
    borderTop: ({ accentColor }) => `8px solid ${accentColor}`,
    background: ({ pageBackground }) => pageBackground,
    marginBottom: '25px',
    display: 'flex',
    justifyContent: 'center',
  },

  boldText: {
    fontWeight: 'bold',
  },
});

export const FundraiserLanding: React.FC = () => {
  const { fundraiserId } = useParams();
  const styles = TEMP_STYLES;
  const classes = useStyles(TEMP_STYLES);
  const [activeTab, setActiveTab] = React.useState<CampaignFaqAllocation>(
    CampaignFaqAllocation.Attendee,
  );
  const handleTabSwitch = (_: React.ChangeEvent<any>, newValue: CampaignFaqAllocation) => {
    setActiveTab(newValue);
  };
  const { experience, team, user, fundraiserPercentage, fundraiser } = useFundraiserLandingData(
    fundraiserId as string,
  );

  const { loading: loadingFAQ, campaignFaqExperience } = usePublicCampaignFAQs(
    CampaignFaqAllocation.Donor,
    experience?.id ?? '',
  );
  const hasFAQs =
    campaignFaqExperience?.[CampaignFaqAllocation.Attendee]?.length ||
    campaignFaqExperience?.[CampaignFaqAllocation.Donor]?.length;

  const raised = formatMoney(fundraiser?.raised ?? 0);
  const goal = formatMoney(fundraiser?.goal ?? 0);

  const STORY_TEXT = `<span style="color: #2A394A; font-family: Inter,sans-serif; font-size: 16px; line-height: 26px; letter-spacing: -0.08px;">${
    fundraiser?.description || ''
  }</span>`;

  const navigate = useNavigate();
  const { openModal } = useModal();
  const {
    isOpen,
    closeModal: closeFAQModal,
    openModal: openFAQModal,
  } = useModal(MODALS.FAQ_MODAL_FUNDRAISER);

  const openShareModal = () => {
    openModal(MODALS.SHARE_SOCIAL_MEDIA);
  };

  const handleDonateRedirect = () => {
    const destination = buildUrl(LANDING_ROUTES.FUNDRAISER_DONATION, { fundraiserId });
    navigate(destination);
  };

  const { campaign } = useCurrentPublicCampaign();

  if (!campaign || !experience) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress style={{ width: '65px', height: '65px' }} color="secondary" />
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <ModalFAQ
        isOpen={isOpen}
        closeModal={closeFAQModal}
        theme={{}}
        campaingFaqExperience={campaignFaqExperience}
        activeTab={activeTab}
        handleTabSwitch={handleTabSwitch}
        CampaignFaqAllocation={CampaignFaqAllocation}
      />
      <main className={classes.content}>
        <CardMedia
          component="img"
          height="450"
          image={
            fundraiser?.hero?.downloadUrl || experience?.experienceHeaderImage?.downloadUrl || ''
          }
          alt="campaign image"
        />
        <div className={classes.contentBox}>
          <Grid container justifyContent="center" spacing={6} sx={{ maxWidth: '1440px' }}>
            <Grid
              marginTop="-60px"
              item
              xs={6}
              md={3}
              container
              flexDirection="column"
              alignItems="center"
            >
              <Grid item>
                <MainAvatar avatarUrl={fundraiser?.logo?.downloadUrl || ''} />
              </Grid>

              {team && (
                <ParticipatingCard
                  type={ParticipatingType.myTeam}
                  name={team?.name}
                  fundraisingGoal={team?.fundraiserGoal}
                  totalRaised={team?.totalRaised}
                  img={team?.avatar?.downloadUrl || undefined}
                  team={team}
                />
              )}

              <ParticipatingCard
                type={ParticipatingType.benefitingCharity}
                name={campaign.name}
                fundraisingGoal={campaign.fundraisingGoal}
                totalRaised={campaign.totalRaised}
                img={
                  experience?.experienceLogoImage?.downloadUrl ||
                  campaign.logo?.downloadUrl ||
                  undefined
                }
                experienceId={experience?.id}
              />
            </Grid>

            <Grid marginTop={4} item xs={10} md={6} container flexDirection="column">
              <Grid container item spacing={3}>
                <Grid container item xs={12} md={8} flexDirection="column">
                  <Grid item>
                    <Typography sx={{ letterSpacing: '1px' }} variant="tabs">
                      {campaign.name} fundraiser
                    </Typography>
                  </Grid>

                  <Grid item marginTop="10px">
                    <Typography variant="h1">{getUserFullName(user)}</Typography>
                  </Grid>

                  <Grid item maxWidth="375px" width="100%" marginTop={2}>
                    <GradientLinearProgress
                      height="16px"
                      variant="determinate"
                      value={fundraiserPercentage}
                    />
                  </Grid>

                  <Grid item marginTop={2}>
                    <Typography variant="body2" sx={{ fontSize: '21px', lineHeight: '20px' }}>
                      <span className={classes.boldText}>{raised}</span> Raised of a goal of {goal}
                    </Typography>
                  </Grid>
                </Grid>
                {loadingFAQ ? (
                  <React.Fragment />
                ) : (
                  <Grid
                    xs={12}
                    md={4}
                    item
                    container
                    direction={['row', 'row', 'column']}
                    spacing={2}
                    marginTop={2}
                  >
                    <Grid item>
                      <LandingButton
                        onClick={handleDonateRedirect}
                        themeColor={styles.accentColor}
                        contained
                      >
                        DONATE
                      </LandingButton>
                    </Grid>

                    <Grid item>
                      <LandingButton onClick={openShareModal} themeColor={styles.accentColor}>
                        SHARE
                      </LandingButton>
                      <ShareSocialMedia />
                    </Grid>

                    {hasFAQs ? (
                      <Grid item>
                        <LandingButton
                          onClick={() => openFAQModal(MODALS.FAQ_MODAL_FUNDRAISER)}
                          themeColor={styles.accentColor}
                        >
                          FAQ
                        </LandingButton>
                        <ShareSocialMedia />
                      </Grid>
                    ) : (
                      <React.Fragment />
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid width="100%" item marginTop="50px">
                <GradientDivider color="#e9e9e9" />
              </Grid>

              <Grid item marginTop="50px">
                <Typography variant="h3" marginBottom={1}>
                  {fundraiser?.title}
                </Typography>

                <HtmlRenderer html={STORY_TEXT} />
              </Grid>

              <Grid item marginTop="50px">
                <GallerySection
                  images={fundraiser?.gallery?.items || []}
                  color={styles.accentColor}
                />
              </Grid>

              <Grid item marginTop="50px">
                <PeopleSection
                  theme={styles}
                  experience={experience}
                  fundraiserId={fundraiserId ?? ''}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </main>
    </div>
  );
};
