/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/react';
import { ListItemIcon, ListItemIconProps } from '@mui/material';

import { Icon, IconName } from './Icon';

interface CustomListItemIconProps extends ListItemIconProps {
  classes?: Record<string, any>;
  style?: Record<string, any>;
  css?: SerializedStyles;
  className?: string;
  key?: number;
  children?: React.ReactNode;
  name?: IconName;
  variant?: any;
  color?: any;
  fontSize?: any;
  viewBox?: string;
  htmlColor?: string;
  customIcon?: string;
}

export const CustomListItemIcon: React.FC<CustomListItemIconProps> = symbolProps => {
  return (
    <ListItemIcon
      sx={{
        minWidth: 'auto',
        color: 'inherit',
      }}
      onClick={symbolProps?.onClick}
      onMouseOver={symbolProps?.onMouseOver}
      onMouseOut={symbolProps?.onMouseOut}
      onMouseDown={symbolProps?.onMouseDown}
      onMouseUp={symbolProps?.onMouseUp}
      onMouseEnter={symbolProps?.onMouseEnter}
      onMouseLeave={symbolProps?.onMouseLeave}
      onWheel={symbolProps?.onWheel}
      onContextMenu={symbolProps?.onContextMenu}
      onAuxClick={symbolProps?.onAuxClick}
    >
      <Icon
        classes={symbolProps?.classes}
        style={symbolProps?.style}
        css={symbolProps?.css}
        className={symbolProps?.className}
        customIcon={symbolProps.customIcon}
        name={symbolProps.name}
        variant={symbolProps.variant}
        color={symbolProps.color}
        fontSize={symbolProps.fontSize}
        viewBox={symbolProps.viewBox}
        htmlColor={symbolProps.htmlColor}
      />
    </ListItemIcon>
  );
};
