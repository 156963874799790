import styled from '@emotion/styled';
import ReceiptOutlined from '@mui/icons-material/ReceiptOutlined';
import Typography from '@mui/material/Typography';

import { BorderRadius, Shade, Shadow } from '@vizsla/theme';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: ${Shadow.Elevation2};
  padding: ${p => p.theme.spacing(0, 3)};
  border-radius: ${BorderRadius.M};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${p => p.theme.spacing(2, 0)};
  gap: ${p => p.theme.spacing(3)};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MoreDetailsLink = styled(Typography)`
  color: ${p => p.theme.palette.primary.main};
  user-select: none;
  cursor: pointer;
`;

export const PriceText = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightRegular};
`;

export const ReceiptOutlinedIcon = styled(ReceiptOutlined)`
  color: ${p => p.theme.palette.primary.main};
`;
export const CounterContainer = styled.div`
  max-width: 12rem;
  display: flex;
  flex-direction: row;
`;

export const OptionsDivider = styled.div`
  display: block;
  height: 100%;
  border-left: dashed 1px ${Shade.Gray[100]};
`;

export const SoldOut = styled.div`
  color: #fff;
  display: block;
  position: absolute;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.06em;
  background-color: #a00;
  padding: 0.5em 5em 0.4em 5em;
  text-shadow: 0 0 0.75em #444;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  font: bold 16px/1.2em Arial, Sans-Serif;
  -webkit-text-shadow: 0 0 0.75em #444;
  -webkit-box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  -webkit-transform: rotate(-15deg) scale(0.75, 1);
  transform: rotate(-15deg) scale(0.75, 1);
  z-index: 10;

  &::before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    margin: -0.3em -5em;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    border: 2px rgba(255, 255, 255, 0.7) dashed;
  }
`;

export const SkeletonContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));
