import { gql, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation StripeCreatePaymentMethod($userId: String!) {
    setup: stripeCreatePaymentMethod(userId: $userId, onlyUser: true) {
      id
      secret
    }
  }
`;

interface Mutation {
  setup: {
    id: string;
    secret: string;
  };
}

interface MutationVariables {
  userId: string;
}

/** Create a setup payment method intent to attach a payment method from an organization that you administrate. */
export const usePaymentMethodSetup = () => {
  const [mutate, { loading }] = useMutation<Mutation, MutationVariables>(MUTATION);

  const execute = async (variables: MutationVariables) => {
    const response = await mutate({ variables });
    const setup = response.data?.setup;

    if (!setup) {
      throw new Error(`Response must have "setup". Got ${typeof setup}`);
    }

    return setup;
  };

  return {
    execute,
    loading,
  };
};
