var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isEmpty, isNil } from 'lodash';
import { INTEGRATION_AMPLY_TYPE, INTEGRATION_DOUBLE_THE_DONATION_TYPE } from '@vizsla/constants';
const AMPLY_API_URL = 'https://private-anon-d516e9ec6b-amply20.apiary-proxy.com';
const DOUBLE_THE_DONATION_API_URL = 'https://doublethedonation.com/api/360matchpro/v1';
const consumeAmplyService = (credentials) => {
    /**
     * List of companies for binding fundraising donations.
     * @docs https://amply20.docs.apiary.io/#reference/0/companies/search-companies
     * @returns A list of companies available in Amply service.
     */
    const { token } = credentials;
    const searchAmplyCompany = (companyName, organizationIds, exclude) => __awaiter(void 0, void 0, void 0, function* () {
        let searchCompanyUrl = `${AMPLY_API_URL}/search/company-name/${companyName}?`;
        if (!isEmpty(organizationIds)) {
            searchCompanyUrl = exclude
                ? `${searchCompanyUrl}?organization_ids=${organizationIds === null || organizationIds === void 0 ? void 0 : organizationIds.join('%')}&exclude=${exclude}`
                : `${searchCompanyUrl}?organization_ids=${organizationIds === null || organizationIds === void 0 ? void 0 : organizationIds.join('%')}`;
        }
        if (exclude) {
            searchCompanyUrl = `${searchCompanyUrl}?exclude=${exclude}`;
        }
        try {
            const res = yield fetch(searchCompanyUrl, {
                method: 'GET',
                headers: {
                    'X-Authorization': token,
                    'Content-Type': 'application/json',
                },
            });
            const jsonData = yield res.json();
            const matchableCompanies = jsonData === null || jsonData === void 0 ? void 0 : jsonData.names.filter(item => item.company.ratio !== 0 &&
                !isNil(item.company.ratio) &&
                item.company.max_match !== 0 &&
                item.company.min_match !== null);
            return {
                companyNames: matchableCompanies.map(company => company.name),
                companyDetails: matchableCompanies.map(item => {
                    return {
                        id: item.company.id,
                        name: item.company.name,
                        ratio: item.company.ratio,
                        maxMatch: item.company.max_match,
                        minMatch: item.company.min_match,
                    };
                })[0],
            };
        }
        catch (e) {
            console.error('Fetch error:', e);
            return null;
        }
    });
    /**
     * Generate a donation with selected Amply company and amount.
     * @docs https://amply20.docs.apiary.io/#reference/0/donations/create-a-new-donation
     * @returns A response from Amply service with tracked data of related donation.
     */
    const createAmplyDonation = (body) => __awaiter(void 0, void 0, void 0, function* () {
        const createDonationUrl = `${AMPLY_API_URL}/api/v1/donation`;
        try {
            const res = yield fetch(createDonationUrl, {
                method: 'POST',
                headers: {
                    'X-Authorization': token,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(body),
            });
            const jsonData = yield res.json();
            return jsonData;
        }
        catch (e) {
            console.error('Fetch error:', e);
            return null;
        }
    });
    /**
     * List of managed organizations.
     * Platforms, Non Profits, and Chapters are all considered different types of Organizations.
     * The process for managing them is comparable.
     * @docs https://amply20.docs.apiary.io/#reference/0/organization/query-an-organization
     * @returns A list of organizations available in Amply service.
     */
    const queryAmplyOrganization = (organizationName, type) => __awaiter(void 0, void 0, void 0, function* () {
        let queryOrganizationUrl = `${AMPLY_API_URL}/api/v1/search/organization/${organizationName}`;
        if (type) {
            queryOrganizationUrl = `${queryOrganizationUrl}/${type}`;
        }
        try {
            const res = yield fetch(queryOrganizationUrl, {
                method: 'GET',
                headers: {
                    'X-Authorization': token,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            const jsonData = yield res.json();
            return jsonData;
        }
        catch (e) {
            console.error('Fetch error:', e);
            return null;
        }
    });
    return {
        queryOrganization: queryAmplyOrganization,
        searchCompany: searchAmplyCompany,
        createDonation: createAmplyDonation,
    };
};
const consumeDoubleTheDonationService = (credentials) => {
    /**
     * Use this endpoint to search for companies by a prefix.
     * Returns an array of companies matched.
     * NOTE: This does not return the full company information, just a summary. Use the detailed company endpoint to get the full information.
     * @docs https://doublethedonation.com/api/360matchpro/apiref.html#!/Legacy/get_api_360matchpro_v1_search_by_company_prefix
     * @returns A list of companies available in Double the donation service.
     */
    const { privateKey, publicKey } = credentials;
    const searchDoubleTheDonationCompany = (companyName) => __awaiter(void 0, void 0, void 0, function* () {
        const searchCompanyUrl = `${DOUBLE_THE_DONATION_API_URL}/search_by_company_prefix?company_name_prefix=${companyName}&key=${privateKey}`;
        try {
            const res = yield fetch(searchCompanyUrl, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });
            const jsonData = yield res.json();
            const matchableCompanies = jsonData.filter(company => company.matching_gift_offered);
            return {
                companyNames: matchableCompanies.map(item => item.company_name),
                companyDetails: matchableCompanies.map(item => {
                    const ratio = item === null || item === void 0 ? void 0 : item.ratio;
                    const getRatio = ratio.includes('Up to')
                        ? ratio.split(':')[0].split('Up to ')[1].trim()
                        : ratio.split(':')[0];
                    return {
                        id: item.id,
                        name: item.company_name,
                        ratio: getRatio,
                        minMatch: item.minimum_matched_amount,
                        maxMatch: item.maximum_matched_amount,
                    };
                })[0],
            };
        }
        catch (e) {
            console.error('Fetch error:', e);
            return null;
        }
    });
    /**
     * Register a new 360MatchPro donation. Pass in data via JSON in the body of a POST request to this endpoint.
     * The model schema can be seen in the schema section of the documentation.
     * @docs https://doublethedonation.com/api/360matchpro/apiref.html#!/360MatchPro32API/post_api_360matchpro_v1_register_donation
     * @returns A response from Double the Donation service with tracked data of related donation.
     */
    const createDoubleTheDonationDonation = (body) => __awaiter(void 0, void 0, void 0, function* () {
        const createDonationUrl = `${DOUBLE_THE_DONATION_API_URL}/register_donation?key=${privateKey}`;
        if (body) {
            body['360matchpro_public_key'] = publicKey;
        }
        try {
            const res = yield fetch(createDonationUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(body),
            });
            const jsonData = yield res.json();
            return jsonData;
        }
        catch (e) {
            console.error('Fetch error:', e);
            return null;
        }
    });
    return {
        createDonation: createDoubleTheDonationDonation,
        searchCompany: searchDoubleTheDonationCompany,
    };
};
export const useIntegrationService = ({ credentials, integrationType }) => {
    const defaultCase = {
        createDonation: null,
        searchCompany: null,
        queryOrganization: null,
    };
    let integrationService;
    switch (integrationType) {
        case INTEGRATION_AMPLY_TYPE:
            integrationService = credentials
                ? consumeAmplyService(credentials)
                : defaultCase;
            break;
        case INTEGRATION_DOUBLE_THE_DONATION_TYPE:
            integrationService = !isNil(credentials)
                ? consumeDoubleTheDonationService(credentials)
                : defaultCase;
            break;
        default:
            integrationService = defaultCase;
    }
    return integrationService;
};
