import React from 'react';
import { MailOutlined } from '@mui/icons-material';
import { Grid, InputAdornment, Typography } from '@mui/material';

import { Field, Form, TextField } from '@vizsla/components';
import { ForgotPasswordValidationSchema } from '@vizsla/constants';
import { PaletteColor } from '@vizsla/theme';
import { validateWithSchema } from '@vizsla/utils';

import { AuthSubmitButton } from 'src/components/auth';

type SendCodeByEmailFormValues = {
  email: string;
};

const initialValues: SendCodeByEmailFormValues = {
  email: '',
};

type SendCodeByEmailFormProps = {
  classes: any;
  onSubmit: (values: SendCodeByEmailFormValues) => void;
  onBackToLogin: (reset: any) => void;
};

export function SendCodeByEmailForm({
  classes,
  onSubmit,
  onBackToLogin,
}: SendCodeByEmailFormProps) {
  const validate = React.useCallback(async (values: SendCodeByEmailFormValues) => {
    return validateWithSchema(ForgotPasswordValidationSchema, values);
  }, []);

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
      {({ handleSubmit, submitting, pristine, hasValidationErrors, form }) => {
        const isDisabled = submitting || pristine || hasValidationErrors;
        return (
          <form onSubmit={handleSubmit} className={classes.loginForm}>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12} paddingLeft={0}>
                <Grid
                  container
                  spacing={0}
                  margin={0}
                  xs={12}
                  justifyContent="center"
                  direction="column"
                >
                  <Grid item marginTop={2} marginBottom={2}>
                    <Typography variant="body1">
                      Enter your email address to reset your password
                    </Typography>
                  </Grid>
                  <Grid item paddingTop={1}>
                    <Field
                      name="email"
                      label="Email Address"
                      component={TextField}
                      e2e-id="email"
                      InputProps={{
                        placeholder: 'Email Address',
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlined />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} paddingLeft={0}>
                <AuthSubmitButton
                  type="submit"
                  loading={submitting}
                  disabled={isDisabled}
                  e2e-id="submit"
                >
                  Send code
                </AuthSubmitButton>
              </Grid>
              <Grid item xs={12} className={classes.textContainer}>
                <Typography
                  style={{ color: PaletteColor.TealText, cursor: 'pointer' }}
                  component="span"
                  onClick={() => onBackToLogin(form.reset)}
                >
                  Back to login
                </Typography>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
}
