import { getFriendlyTimeZone } from '@vizsla/utils';
import { useGetOrganizationByExperienceQuery } from '@vizsla/graphql';
export const useTimeZoneByAddress = ({ address, organizationTimeZone = 'UTC', experience, }) => {
    var _a, _b, _c, _d, _e, _f;
    const organizationData = useGetOrganizationByExperienceQuery({
        variables: {
            id: experience === null || experience === void 0 ? void 0 : experience.id,
        },
    });
    const timeZone = ((_b = (_a = organizationData === null || organizationData === void 0 ? void 0 : organizationData.data) === null || _a === void 0 ? void 0 : _a.experience) === null || _b === void 0 ? void 0 : _b.timeZone) ||
        ((_f = (_e = (_d = (_c = organizationData === null || organizationData === void 0 ? void 0 : organizationData.data) === null || _c === void 0 ? void 0 : _c.experience) === null || _d === void 0 ? void 0 : _d.campaign) === null || _e === void 0 ? void 0 : _e.vizslaOrganization) === null || _f === void 0 ? void 0 : _f.timeZone) ||
        'utc';
    const formattedTimeZone = getFriendlyTimeZone(timeZone);
    return { timeZone, formattedTimeZone };
};
