import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DateTime } from 'luxon';

import { useModal } from '@vizsla/hooks';
import { AsyncContent, HtmlRenderer, Modal } from '@vizsla/components';
import { RegistrationOption, useRegistrationOptionQuery } from '@vizsla/graphql';
import { encodedHtmlIsEmpty, formatDateIntl, formatMoney } from '@vizsla/utils';

import { MODALS } from '../../../constants/modals';
import { GridAutoColumns, GridItem } from './ExperienceOptionModal.style';

export function ExperienceOptionModal() {
  const { isOpen, closeModal, args } = useModal<RegistrationOption>(
    MODALS.REGISTRATION_DETAILS_MODAL,
  );
  const { data, loading } = useRegistrationOptionQuery({
    skip: !args?.id,
    variables: { id: args?.id as string },
  });

  const registrationOption = React.useMemo(() => {
    return data?.registrationOption as RegistrationOption | undefined;
  }, [data]);

  const registrationAvailability = React.useMemo(() => {
    const { registrationStartDate, registrationEndDate } = registrationOption ?? {};
    if (!registrationStartDate || !registrationEndDate) return ['Unlimited'];
    return [registrationStartDate, registrationEndDate].map(date =>
      formatDateIntl(date, DateTime.DATETIME_MED),
    );
  }, [registrationOption]);

  const minimumFundraising = React.useMemo(() => {
    const { fundraisingMinimum = 0 } = registrationOption ?? {};
    if (fundraisingMinimum === 0) return 'Unavailable';
    return formatMoney(fundraisingMinimum);
  }, [registrationOption]);

  const description = React.useMemo(() => {
    if (!registrationOption?.description || encodedHtmlIsEmpty(registrationOption?.description))
      return 'Description unavailable';
    return registrationOption?.description;
  }, [registrationOption]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth DialogProps={{ maxWidth: 'sm' }}>
      <Modal.Title>
        <Box textAlign="center">{args?.name}</Box>
      </Modal.Title>
      <Modal.Content>
        <AsyncContent loading={loading}>
          <GridAutoColumns>
            <GridItem>
              <Typography variant="subtitle2">Registration availability</Typography>
              {registrationAvailability.map(date => (
                <Typography key={date} variant="caption">
                  {date}
                </Typography>
              ))}
            </GridItem>
            <GridItem>
              <Typography variant="subtitle2">Minimum fundraising</Typography>
              <Typography variant="caption">{minimumFundraising}</Typography>
            </GridItem>
          </GridAutoColumns>
          <Box mt={3}>
            <HtmlRenderer html={description} />
          </Box>
        </AsyncContent>
      </Modal.Content>
    </Modal>
  );
}
