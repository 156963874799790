import React from 'react';
import { Form } from 'react-final-form';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

import { useModal, useAppAuth } from '@vizsla/hooks';
import { PaletteColor, Shade } from '@vizsla/theme';
import { Field, Modal, TextField } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { AuthSubmitButton } from 'src/components/auth';

const useStyles = makeStyles({
  loginForm: {
    marginRight: 'auto',
    width: 440,
  },
  signupText: {
    color: Shade.Gray[400],
  },
  textContainer: {
    margin: 'auto !important',
  },
});

export const ConfirmEmailModal: React.FC = () => {
  const classes = useStyles();
  const { isOpen, closeModal, openModal, args } = useModal(MODALS.CONFIRM_EMAIL_MODAL);
  const { confirmEmail } = useAppAuth();

  const initialValues = {
    email: args?.email,
  };

  const onLogin = (reset: (initialValues?: Partial<any> | undefined) => void) => {
    reset();
    closeModal();
    openModal(MODALS.LOGIN_MODAL);
  };

  const onSubmit = React.useCallback(
    async data => {
      await confirmEmail(data);

      closeModal();
      openModal(MODALS.LOGIN_MODAL);
    },
    [closeModal, confirmEmail, openModal],
  );

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Title>Confirm your Email</Modal.Title>
      <Modal.Content>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, submitting, pristine, hasValidationErrors, form }) => {
            const isDisabled = submitting || pristine || hasValidationErrors;

            return (
              <form onSubmit={handleSubmit} className={classes.loginForm}>
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={12} paddingLeft={0}>
                    <Grid container spacing={0} xs={12} justifyContent="center" direction="column">
                      <Grid item paddingTop={2}>
                        <Field
                          name="email"
                          label="Email Address"
                          component={TextField}
                          e2e-id="email"
                          fullWidth
                        />
                      </Grid>
                      <Grid item paddingTop={2}>
                        <Field
                          name="code"
                          label="Code"
                          component={TextField}
                          e2e-id="code"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} paddingLeft={0}>
                    <AuthSubmitButton
                      type="submit"
                      loading={submitting}
                      disabled={isDisabled}
                      e2e-id="submit"
                    >
                      Confirm
                    </AuthSubmitButton>
                  </Grid>
                  <Grid item xs={12} className={classes.signupText}>
                    <Typography className={classes.signupText} component="span">
                      Already have an Account?&nbsp;
                    </Typography>
                    <Typography
                      style={{ color: PaletteColor.TealText, cursor: 'pointer' }}
                      component="span"
                      onClick={() => onLogin(form.reset)}
                    >
                      Log In
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Modal.Content>
    </Modal>
  );
};
