import React from 'react';
import { Grid, Typography, styled } from '@mui/material';

import { AssetByRegistrationOptionView, AssetSwagbagList, Maybe } from '@vizsla/graphql';

import { EMPTY_IMAGE } from 'src/assets';
import { areAllValuesEmpty } from 'src/utils/validation';

const StyledImageGrid = styled(Grid)({
  width: '100px',
  '& > img': {
    display: 'block',
    width: '100%',
    height: '100%',
    maxHeight: '100px',
    objectFit: 'contain',
  },
});

interface StoreAssetTableItemProps {
  storeItem: (AssetByRegistrationOptionView | AssetSwagbagList) & { uri?: Maybe<string> };
}

export const SwagBagName: React.FC<StoreAssetTableItemProps> = ({ storeItem }) => {
  const imageSrc = storeItem.uri || EMPTY_IMAGE;

  const subNamesLabels = React.useMemo(() => {
    if (!areAllValuesEmpty(storeItem?.variantsValues)) {
      return Object.entries(storeItem?.variantsValues).reduce(
        (acum, item, index) =>
          index === 0 ? `${item[0]}:${item[1]}` : `${acum} ${item[0]} : ${item[1]}`,
        '',
      );
    }
    return null;
  }, [storeItem]);

  return (
    <Grid
      xs={10}
      container
      wrap="nowrap"
      alignItems="center"
      gap={2.5}
      data-test="swagbagTitle"
      className="swagbagTitle"
    >
      <StyledImageGrid item>
        <img src={imageSrc} alt="Asset" />
      </StyledImageGrid>

      <Grid item>
        <Typography variant="body2" fontWeight="bolder">
          {storeItem.name}
        </Typography>

        {subNamesLabels && (
          <Typography variant="body2" fontWeight="bolder">
            {subNamesLabels}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
