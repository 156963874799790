import React from 'react';
import { Grid, Typography } from '@mui/material';
import { LocalCafeOutlined } from '@mui/icons-material';

import { useSelectedTeamMetricsLeaderboard } from 'src/hooks';

import { MostDonationsCard } from './MostDonationsCard';
import { MostDonorCard } from './MostDonorCard';
import { MostAverageDonationsCard } from './MostAverageDonationsCard';

export function TeamLeaderBoard() {
  const { data, loading } = useSelectedTeamMetricsLeaderboard();

  return (
    <Grid container spacing={2}>
      <Grid item container alignItems="center" justifyContent="center">
        <Grid item display="flex" marginRight="8px">
          <Typography variant="h3" textAlign="center" alignItems="center">
            <LocalCafeOutlined />
          </Typography>
        </Grid>

        <Typography variant="h3" textAlign="center" alignItems="center">
          Leaderboard
        </Typography>
      </Grid>

      <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
        <MostDonationsCard data={data?.totalDonations ?? {}} loading={loading} />
      </Grid>

      <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
        <MostDonorCard data={data?.totalDonors ?? {}} loading={loading} />
      </Grid>

      <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
        <MostAverageDonationsCard data={data?.average ?? {}} loading={loading} />
      </Grid>
    </Grid>
  );
}
