import * as yup from 'yup';

import { getNowDate, toDateTimeFromISO } from '@vizsla/utils';

export const DonationOptionsSchema = yup.object({
  donation: yup.object({
    donationOption: yup
      .object({
        id: yup.string().nullable().required(),
      })
      .nullable()
      .required('Donation Option Is required'),
  }),
});

const currentDate = String(toDateTimeFromISO(getNowDate()));
export const DONATION_FORM_SCHEMA = yup.object({
  frecuency: yup.string().required(),
  amount: yup.number().min(1).required(),
  message: yup.string(),
  makeAnonymous: yup.boolean(),
  companyName: yup.string(),
  startDate: yup.date().when('frecuency', {
    is: valor => valor !== 'one-time',
    then: yup
      .date()
      .required('Start Date is required')
      .min(currentDate, 'Start Date can not less to current date'),
    otherwise: yup.date(),
  }),
  endDate: yup.date().when('frecuency', {
    is: valor => valor !== 'one-time',
    then: yup.date().min(yup.ref('startDate'), 'End Date can not less to start date'),
    otherwise: yup.date(),
  }),
});
