import React, { useMemo } from 'react';
import { flatMap, omit, groupBy, isNil } from 'lodash';
import { useForm } from 'react-final-form';
import { create } from 'zustand';

import { Form, FORM_ARRAY_MUTATOR } from '@vizsla/components';
import {
  selectAllRegistrations,
  ShoppingCartRegistrationData,
  useCurrentUser,
  useModal,
  useNotification,
  useShoppingCartState,
} from '@vizsla/hooks';
import { LANDING_ROUTES } from '@vizsla/constants';
import { buildUrl } from '@vizsla/utils';

import { ExperiencePurchaseLayout } from 'src/layouts';
import {
  useOpenExperiencePages,
  useRegistrationOptionsValidationSchema,
  useSelectedExperience,
  useAutoFillAttendeeInfo,
  useSelectedWaiver,
} from 'src/hooks';
import { MODALS } from 'src/constants/modals';
import { Warning } from 'src/modals';

import { Button, Buttons, FormContainer, StepTitle } from './ExperienceAttendeesStep.style';
import { ExperienceAttendeeForm, ExperienceAttendeeFormValues } from './ExperienceAttendeeForm';
import { ExperienceWaiverForm, ExperienceWaiverFormValues } from './ExperienceWaiverForm';
import { ExperienceAttendeeTabs } from './ExperienceAttendeeTabs/ExperienceAttendeeTabs';
import { useValidateEmailAvailability } from '../../hooks/registrationOption/useValidateEmailAvailability';

const scrollToEmailField = (index: number) => {
  const emailField = document.querySelector(
    `input[name="attendees[${index}].email"]`,
  ) as HTMLInputElement;
  if (!emailField) return;
  // smooth scroll to email field
  emailField.scrollIntoView({ behavior: 'smooth' });
  // when the field is visible, focus it
  const observer = new IntersectionObserver(
    entries => {
      if (entries[0].isIntersecting) {
        emailField.focus();
        observer.disconnect();
      }
    },
    { threshold: 0.5 },
  );
};

interface FormValues extends ExperienceWaiverFormValues {
  attendees: ExperienceAttendeeFormValues[];
  questions: Record<string, string>[];
}

const mapRegistrationsToFormValues = (
  registrations: ShoppingCartRegistrationData[],
): FormValues => {
  const attendees: FormValues['attendees'] = registrations.map(r =>
    omit(r.attendee, 'waiverSignature'),
  );
  const questions: FormValues['questions'] = registrations.map(
    r => (r.questions ?? {}) as Record<string, string>,
  );
  const waiverSignature = registrations.at(0)?.attendee?.waiverSignature ?? '';
  const waiverAcceptance = waiverSignature !== '';
  return {
    attendees,
    questions,
    waiverSignature,
    waiverAcceptance,
  };
};

const groupAttendeesByRegOption = (state, waiverInfo) => {
  const registrations = selectAllRegistrations(state);
  const grouped = groupBy(registrations, i => i.option.id);

  const indexes = Object.keys(grouped);

  const items = indexes.map(id => {
    const items = grouped[id];

    return { items };
  });
  const groupAttendees = flatMap(items, 'items');
  return groupAttendees;
};

export function ExperienceAttendeesStep() {
  const { firstName } = useCurrentUser();
  const waiverInfo = useSelectedWaiver(state => state.data);
  const experience = useSelectedExperience(state => state.data);
  const registrations = useShoppingCartState(state => selectAllRegistrations(state));
  const { openRegistrationForm, openSelectTeam, openBillingAddress } = useOpenExperiencePages();
  const [active, setActive] = React.useState(0);
  const { validateForm } = useRegistrationOptionsValidationSchema(registrations[active]);

  const validateEmailAvailability = useValidateEmailAvailability();
  const { error } = useNotification();

  const handleStartOver = () => openRegistrationForm();

  const isTicketing = useMemo(() => {
    return experience?.experienceType === 'Ticketing';
  }, [experience]);

  React.useEffect(() => {
    if (registrations.length !== 0) return;
    openRegistrationForm();
  }, [openRegistrationForm, registrations]);

  const initialValues = React.useRef<FormValues>(mapRegistrationsToFormValues(registrations));
  const formValuesRef = React.useRef<any>(null);
  const groupAttendeesByOptions = useShoppingCartState(state =>
    groupAttendeesByRegOption(state, waiverInfo),
  );

  useAutoFillAttendeeInfo({
    activeAttendeeIndex: active,
    formReference: formValuesRef,
    registrations,
  });

  const { openModal } = useModal();

  const onWarning = () =>
    openModal(MODALS.WARNING, {
      message:
        'By registering for this event, you are committing to a fundraising obligation. Ensure that every attendee added to this registration has a valid payment method set up in their profile prior to the collection date.',
      url: buildUrl(LANDING_ROUTES.EXPERIENCE_TEAM, {
        experienceId: experience?.id as string,
      }),
    });

  const handleSubmit = async values => {
    const emailsValidated = await Promise.all(
      values.attendees.map(attendee =>
        validateEmailAvailability({
          email: attendee.email,
          experienceId: experience?.id as string,
        }),
      ),
    );
    if (emailsValidated.some(isAvailable => !isAvailable)) {
      const errors = emailsValidated.map(valid => {
        if (valid) return undefined;
        return { email: `This email is already registered for this experience.` };
      });
      const firstErrorIndex = errors.findIndex(error => error);
      error('Some emails are already registered for this experience.');
      setActive(firstErrorIndex);
      scrollToEmailField(firstErrorIndex);
      return { attendees: errors };
    }

    if (isTicketing) {
      openBillingAddress(experience?.id ?? '');
    } else if (
      !isNil(experience?.fundraisingSettings?.generalSettings) &&
      values?.attendees.length > 1 &&
      experience?.fundraisingSettings?.generalSettings?.isObligation
    ) {
      onWarning();
    } else {
      openSelectTeam();
    }
    return {};
  };
  return (
    <ExperiencePurchaseLayout>
      <StepTitle>
        Sweet, {firstName}. You&apos;re about to be in for the {experience?.name}!
      </StepTitle>
      <Form
        initialValues={initialValues.current}
        onSubmit={handleSubmit}
        validate={validateForm}
        mutators={{ ...FORM_ARRAY_MUTATOR }}
      >
        {form => {
          (formValuesRef as any).current = form;

          return (
            <FormContainer>
              <ExperienceAttendeeTabs
                registrations={groupAttendeesByOptions}
                active={active}
                onActivate={setActive}
              />

              {groupAttendeesByOptions.map((reference, index) => (
                <div key={reference.id} hidden={active !== index}>
                  <ExperienceAttendeeForm referenceId={reference.id} index={index} />
                </div>
              ))}

              {experience?.waiverEnabled && <ExperienceWaiverForm />}

              <Buttons>
                <Button variant="outlined" onClick={handleStartOver}>
                  Start Over
                </Button>

                <Button loading={form.submitting} onClick={form.handleSubmit}>
                  Continue
                </Button>
              </Buttons>
            </FormContainer>
          );
        }}
      </Form>
      <Warning />
    </ExperiencePurchaseLayout>
  );
}
