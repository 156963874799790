import React, { useEffect, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';

import { Counter, Form, TableColumn, TableData } from '@vizsla/components';
import {
  useInventoriesListByIdQuery,
  useGetAllFileListQuery,
  useMerchandiseExperienceQuery,
  useGetExperienceByIdQuery,
} from '@vizsla/graphql';
import {
  selectAllAssets,
  selectAllRegistrations,
  selectAllVizslaFee,
  selectAllPaymentFee,
  selectAllRoundUp,
  useShoppingCartState,
  selectAllSwagBags,
} from '@vizsla/hooks';
import { formatMoney } from '@vizsla/utils';

import { ExperiencePurchaseLayout } from 'src/layouts';
import { useCurrentExperience, useOpenExperiencePages } from 'src/hooks';

import { Button, Buttons, Content, StepTitle } from './ExperienceAssetsStep.style';
import { AssetNameCell } from './AssetNameCell';

const selectExperienceAssetsState = state => ({
  items: selectAllAssets(state),
  registration: selectAllRegistrations(state),
  vizslaFee: selectAllVizslaFee(state).at(0),
  paymentFee: selectAllPaymentFee(state).at(0),
  roundUp: selectAllRoundUp(state).at(0),
  addItem: state.add,
  removeItem: state.remove,
  swagBags: selectAllSwagBags(state),
});

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
});

export function ExperienceAssetsStep() {
  const { experienceId } = useParams();
  const classes = useStyles();
  const { items, registration, addItem, removeItem, vizslaFee, paymentFee, roundUp, swagBags } =
    useShoppingCartState(selectExperienceAssetsState);
  const { data: dataExperience } = useGetExperienceByIdQuery({
    variables: {
      id: experienceId ?? '',
    },
  });
  const {
    openDonationForm,
    openRegistrationForm: openOptionsSelector,
    openSwagBagsForm,
    openBillingAddress,
    openSelectTeam,
  } = useOpenExperiencePages();

  const { experience: initialExperience } = useCurrentExperience();

  const { data, loading: loadingAssets } = useMerchandiseExperienceQuery({
    variables: {
      filter: {
        experienceId: {
          equals: experienceId,
        },
      },
    },
  });

  const { data: dataInventory } = useInventoriesListByIdQuery({
    variables: {
      filter: {
        OR: data?.merchandise?.items?.map(asset => ({
          id: {
            equals: asset.inventoryId,
          },
        })),
      },
    },
  });

  const organizedData = dataInventory?.inventoriesList?.items.reduce((acc, item) => {
    acc[item.id ?? ''] = {
      id: item?.id,
      unitPrice: item?.unitPrice,
      storeAssetId: item?.storeAssetId,
      OrgID: item?.OrgID,
      sales: item?.sales,
      profit: item?.profit ?? 0,
      refund: item?.refund,
      priceSales: item?.priceSales,
      storeAssetName: item?.storeAssetName,
      variantsValues: item?.variantsValues,
    };
    return acc;
  }, {});

  if (initialExperience?.experienceType === 'Ticketing') {
    openOptionsSelector(experienceId);
  }

  useEffect(() => {
    if (vizslaFee) removeItem(vizslaFee);
    if (paymentFee) removeItem(paymentFee);
    if (roundUp) removeItem(roundUp);
  }, [vizslaFee, paymentFee, roundUp, removeItem]);

  const assets = useMemo(() => {
    const assets: any[] = [];
    const items = data?.merchandise?.items ?? [];
    for (let index = 0; index < items.length; index += 1) {
      const item = items[index];
      const unitPrice = organizedData?.[item?.inventoryId ?? '']?.unitPrice ?? 0;

      if (item?.continueSalesWhenOutOfStock) {
        assets.push({ ...item, unitPrice });
      }
    }
    return assets;
  }, [data, organizedData]);

  const pictureIds = useMemo(() => {
    return assets.map(asset => asset.picture as string);
  }, [assets]);

  const { data: assetsPictures, loading: loadAssetsPictures } = useGetAllFileListQuery({
    variables: { filter: { id: { in: pictureIds } } },
  });

  const loading = useMemo(
    () => loadingAssets || loadAssetsPictures,
    [loadingAssets, loadAssetsPictures],
  );

  const picturesData = useMemo(() => {
    return assetsPictures?.filesList?.items || [];
  }, [assetsPictures]);

  const finalData = useMemo(() => {
    return assets.map(asset => {
      const image = picturesData.find(itF => itF.id === asset.picture);

      return {
        id: asset.id ?? '',
        assetId: asset.assetId ?? '',
        inventoryId: asset.inventoryId ?? '',
        name: asset.name ?? 'Item',
        available: asset.available ?? 0,
        continueSalesWhenOutOfStock: asset.continueSalesWhenOutOfStock ?? false,
        uri: image?.downloadUrl ?? undefined,
        price: asset.price ?? 0,
        variantsValues: asset.variantsValues ?? {},
        unitPrice: asset.unitPrice ?? 0,
      };
    });
  }, [assets, picturesData]);

  const assetCount = (optionId: string) => {
    return items.filter(item => item.asset.id === optionId).length;
  };

  const addAsset = (data: typeof finalData[number], price: number) => {
    addItem({
      type: 'asset',
      name: data.name,
      price,
      asset: {
        id: data.id,
        name: data.name,
        uri: data.uri,
        inventoryId: data?.inventoryId,
        assetId: data?.assetId,
        variants: data?.variantsValues,
        unitPrice: data?.unitPrice ?? 0,
      },
    });
  };

  const removeAsset = (optionId: string) => {
    const item = items.find(item => item.asset.id === optionId);
    if (!item) return;
    removeItem({ id: item.id });
  };

  const columns: TableColumn<typeof finalData[number]>[] = [
    {
      key: 'name',
      title: 'Name',
      sortable: true,
      render(root, item) {
        return <AssetNameCell storeItem={item} />;
      },
    },
    {
      key: 'price',
      title: 'Price',
      sortable: true,
      render(root, item) {
        return formatMoney(item.price, 2);
      },
    },
    {
      key: 'counter',
      title: 'Counter',
      render: (root, item) => {
        const value = assetCount(item.id);

        if (item.available <= 0 && item.continueSalesWhenOutOfStock === false) {
          return (
            <Grid item xs={5}>
              <Typography textAlign="center">Sold out</Typography>
            </Grid>
          );
        }

        return (
          <Grid sx={{ display: 'flex', alignSelf: 'center' }} item xs={5}>
            <Counter
              value={value}
              max={item.continueSalesWhenOutOfStock ? 1000 : item.available}
              onIncrease={() => addAsset(item, item.price)}
              onDecrease={() => removeAsset(item?.id)}
            />
          </Grid>
        );
      },
    },
  ];

  const handleBack = () => {
    if (initialExperience?.experienceType === 'Ticketing' || swagBags.length === 0) {
      openSelectTeam(experienceId);
    } else {
      openSwagBagsForm(experienceId);
    }
  };

  const handleStartOver = () => {
    openOptionsSelector(experienceId);
  };

  const handleSubmit = () => {
    const type = registration.filter(item => item.subtype === 'Ticketing');
    if (type.length > 0) {
      return openBillingAddress(experienceId);
    }
    return openDonationForm(experienceId);
  };

  useEffect(() => {
    if (dataExperience?.experience && !dataExperience?.experience?.isStoreEnabled) {
      const type = registration.filter(item => item.subtype === 'Ticketing');
      if (type.length > 0) {
        openBillingAddress(experienceId);
      } else {
        openDonationForm(experienceId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataExperience?.experience]);

  return (
    <ExperiencePurchaseLayout>
      <Form onSubmit={handleSubmit}>
        {form => (
          <Content>
            <StepTitle>Would you like to buy something?</StepTitle>

            <TableData
              tableContainerProps={{ className: classes.tableContainer }}
              loading={loading}
              dataSource={finalData}
              columns={columns}
              tableProps={{ stickyHeader: true }}
            />

            <Buttons>
              <Button variant="outlined" onClick={handleStartOver}>
                Start Over
              </Button>

              <Button variant="outlined" onClick={handleBack}>
                Back
              </Button>

              <Button onClick={form.handleSubmit} disabled={form.invalid}>
                Continue
              </Button>
            </Buttons>
          </Content>
        )}
      </Form>
    </ExperiencePurchaseLayout>
  );
}
