import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { ApolloQueryResult } from '@apollo/client';

import { useCurrentUser } from '@vizsla/hooks';
import { StripePaymentMethodsUserQuery } from '@vizsla/graphql';

import { usePaymentMethodSetup } from 'src/hooks/stripe';
import { StripeProvider } from 'src/providers/StripeProvider';

import { PaymentMethodForm, Values } from './PaymentMethodForm';
import { Container, LoaderContainer, Title } from './CreatePaymentMethod.styles';

interface SavePaymentProps {
  setOtherMethod: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
  existPaymentMethod: boolean;
  setDataCreditCartInit: React.Dispatch<React.SetStateAction<any | null>>;
}
export const SavePaymentMethod = (props: SavePaymentProps) => {
  const [fetching, setFetching] = useState(false);

  const [stripeSecret, setStripeSecret] = useState<string>();

  const { user } = useCurrentUser();
  const userId = React.useMemo(() => user?.id, [user]);
  const { execute: createSetup } = usePaymentMethodSetup();

  const initialize = async () => {
    if (!userId || Boolean(stripeSecret)) return;

    setFetching(true);

    try {
      const setup = await createSetup({ userId });
      setStripeSecret(setup.secret);
      setFetching(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!stripeSecret) {
      initialize();
    }

    return () => {
      setStripeSecret(undefined);
    };
  }, []);

  const onCancel = () => {
    props.setOtherMethod(false);
  };

  const handleSubmit = async (values: Values, stripe: Stripe, elements: StripeElements) => {
    if (props.existPaymentMethod) {
      // process for add payment methods when the user alreagy has one
      const response = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
      });
      props.setOtherMethod(false);
      props.refetch();
      if (response.error) {
        console.error(response.error.message);
      }
    } else {
      // procces for add teh firts payment method
      props.setDataCreditCartInit({
        stripe,
        elements,
      });
    }
  };
  return (
    <React.Fragment>
      {fetching || !stripeSecret ? (
        <React.Fragment>
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Container>
            <StripeProvider clientSecret={stripeSecret}>
              <PaymentMethodForm
                labelSubmit="Add new payment method"
                onSubmit={handleSubmit}
                onCancel={onCancel}
                existPaymentMethod={props.existPaymentMethod}
              />
            </StripeProvider>
          </Container>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
