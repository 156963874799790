import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'material-react-toastify';

import { App } from './App';
import { ApplicationProvider } from './providers';

import 'material-react-toastify/dist/ReactToastify.css';
import './index.css';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ApplicationProvider>
    <App />
    <ToastContainer />
  </ApplicationProvider>,
);
